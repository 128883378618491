<template>
  <v-dialog
    v-model="isOpen"
    max-width="1600px"
    hide-overlay
    scrollable
    persistent
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="modal-text-action pl-4">
          {{ $t('season_report.insert_harvest_data_title') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn class="mr-2" icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <sowings-to-harvest-form
        @form-updated="updateFormData"
        @sowings-count="sowingsCount"
        ref="loadPlantings"
      />
      <v-card-actions class="distance-buttons">
        <v-btn
          class="gray--text text-none button-register-close"
          @click="close"
        >
          {{ $t('cancelar') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="validateForm"
          class="white--text text-none button-register-confirm"
          text
        >
          {{ $t('harvest.register_harvests') }}
          <v-icon class="icon-button" small>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-confirm
      :dialog="dialogConfirm"
      @close="closeDialogConfirm"
      @confirm="registerAllHarvests"
    />
  </v-dialog>
</template>

<script>
import SowingsToHarvestForm from '@/components/Steppers/SowingsToHarvestForm.vue'
import DialogConfirm from '@/components/Dialogs/DialogConfirm.vue'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'ModalMultiHarvest',

  components: { SowingsToHarvestForm, DialogConfirm },

  data() {
    return {
      isOpen: false,
      dialogConfirm: false,
      totalSowingsCount: null,
      harvests: [
        {
          harvestId: null,
          harvestDate: null,
          sacs: null,
          desiccation: false,
          lost: false,
        },
      ],
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
      if (this.$refs.loadPlantings) {
        this.$refs.loadPlantings.loadPlantingsByPlots(this.currentFarmId)
        this.$refs.loadPlantings.resetValidators()
      }
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) this.close()
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
  },

  methods: {
    sowingsCount(count) {
      this.totalSowingsCount = count
    },
    validateForm() {
      if (this.validates(this.harvests)) {
        return
      }
      if (this.harvests.length < this.totalSowingsCount) {
        return this.showDialogConfirm()
      }
      this.registerAllHarvests()
    },
    async registerAllHarvests() {
      try {
        await this.$api.semeaduras.colheita.createManyHarvests({
          harvests: this.harvests,
        })
        logEvent(events.harvests.clickedButton_saveMultipleHarvest)

        this.$root.$emit('notify', 'success', this.$t('succesfuly_saved'))

        this.close()

        this.$router.go()
      } catch (e) {
        throw Error(e)
      }
    },
    showDialogConfirm() {
      this.dialogConfirm = true
    },
    closeDialogConfirm() {
      this.dialogConfirm = false
    },
    close() {
      this.$emit('close')
    },
    updateFormData(filledHarvests) {
      this.harvests = filledHarvests
    },
    validates(filledHarvests) {
      return (
        this.validateKeyHarvest(filledHarvests) ||
        this.validateProductivity(filledHarvests) ||
        this.validateHarvestDate(filledHarvests)
      )
    },
    validateKeyHarvest(filledHarvests) {
      return filledHarvests.length == 0 ? true : undefined
    },
    validateHarvestDate(filledHarvests) {
      const invalidHarvestDate = filledHarvests.some(
        (harvest) => !harvest.harvested_at && !harvest.lost
      )

      return invalidHarvestDate ? true : undefined
    },
    validateProductivity(filledHarvests) {
      const invalidProductivity = filledHarvests.some(
        (harvest) =>
          (harvest.productivity <= 0 || harvest.productivity == null) &&
          !harvest.lost
      )

      return invalidProductivity ? true : undefined
    },
  },
}
</script>

<style scoped>
.modal-text-action {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  padding: 17px;
}
.button-register-confirm {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}
.button-register-close {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: rgba(0, 0, 0, 0.4);
}
.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}

.distance-buttons {
  padding: 12px 30px;
}
</style>
