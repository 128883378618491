<template>
  <v-container>
    <v-card
      class="align-content-center card-register"
      max-width="100%"
      heigth="431px"
      outlined
      single-line
    >
      <v-row>
        <v-col md="8" sm="8" class="offset-md-2 py-16">
          <v-list-item>
            <v-list-item-content>
              <div class="previous-text">
                {{ $t('register.secundary_title_whitout_bag_price') }}
              </div>
              <v-list-item-title class="title-register mb-1">
                {{ $t('register.bag_price') }}
              </v-list-item-title>
              <v-list-item-subtitle class="wrap-text">
                <p class="description-register">
                  {{ $t('register.secundary_text_whitout_bag_price') }}
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-img
              alt="farmer-price"
              src="icons/ic-farmer-price.svg"
              max-width="160px"
            />
          </v-list-item>

          <v-row no-gutters>
            <v-col>
              <v-card-actions>
                <v-btn
                  outlined
                  text
                  class="white--text text-none button-register"
                  @click="openCropBagPricesForm"
                >
                  {{ $t('register.price') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <modal-crop-bag-price
      :current-farm="currentFarm"
      :dialog="openCropBagPriceModal"
      @close="closeCropBagPriceForm"
    ></modal-crop-bag-price>
  </v-container>
</template>

<script>
import ModalCropBagPrice from '@/pages/semeaduras/ModalCropBagPrice.vue'
export default {
  components: { ModalCropBagPrice },
  data() {
    return {
      cropBagPrices: null,
      openCropBagPriceModal: false,
    }
  },

  props: {
    currentFarm: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    openCropBagPriceModal(value) {
      if (!value) {
        this.$emit('close')
      }

      if (value && this.currentFarm.bag_price) {
        this.cropBagPrices = this.currentFarm.bag_price
      }
    },
  },

  methods: {
    openCropBagPricesForm(cropBagPrices) {
      this.cropBagPrices = cropBagPrices
      this.openCropBagPriceModal = true
    },

    closeCropBagPriceForm() {
      this.cropBagPrices = null
      this.openCropBagPriceModal = false
    },
  },
}
</script>

<style>
.card-register {
  border-color: white;
}
.previous-text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #51594f;
}

.button-register {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-color: #39af49;
  border-color: #39af49;
}

.title-register {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  color: black;
  white-space: initial !important;
}

.description-register {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #51594f;
}

.wrap-text {
  text-overflow: initial !important;
  white-space: initial !important;
}
</style>
