<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ExecucaoHeader from '@/pages/execucao/Header.vue'
import ExecucaoProgramaHeader from '@/pages/execucao/ProgramaHeader.vue'
import ExecucaoProgramaImport from '@/pages/execucao/ProgramaImport.vue'
import ExecucaoProgramaList from '@/pages/execucao/ProgramaList.vue'
import ModalColheita from '@/pages/execucao/ModalColheita.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { convertToTargetUnit } from '@/components/Form/converter'
import MessageSowingReadyToHarvest from '@/components/MessageSowingReadyToHarvest.vue'
import Loader from '@/components/Loaders/Loader.vue'

export default {
  mixins: [PermissionMixin],

  components: {
    ExecucaoHeader,
    ExecucaoProgramaHeader,
    ExecucaoProgramaImport,
    ExecucaoProgramaList,
    ModalColheita,
    MessageSowingReadyToHarvest,
    Loader,
  },

  data() {
    return {
      isLoading: false,
      isOpenModalHarvest: false,
    }
  },

  watch: {
    async currentFarmId(farmId) {
      if (this.$route?.query?.fazenda_id !== farmId) {
        this.$router.push({
          path: 'sowings',
          query: { fazenda_id: farmId },
        })
      }
    },

    programa(program) {
      this.verifyProgramHasWhiteMold(program)
    },
  },

  async created() {
    this.SET_SEMEADURA_ID(parseInt(this.$route.query.semeadura_id))
    await this.loadPlantings()
    await this.fetchDiseases()
  },

  computed: {
    ...mapGetters('execucoes', [
      'fazenda',
      'semeadura',
      'cultivar',
      'programa',
      'execucoes',
    ]),
    ...mapGetters('farms', ['currentFarmId', 'currentFarm', 'enabledFarm']),
    ...mapGetters('semeaduras', ['semeaduras']),

    isEndOfPrograma() {
      if (!this.programa || !this.execucoes) return false

      const beforeLastIndex = this.execucoes.length - 2

      if (beforeLastIndex < 0) return true

      return !!this.execucoes[beforeLastIndex].data_realizada
    },

    productivity() {
      return convertToTargetUnit(
        this.semeadura.colheita.produtividade,
        'performance'
      )
    },

    bagPrice() {
      const bag = this.currentFarm?.bag_price?.find(
        (bag) => bag.crop_id === this.semeadura.crop_id
      )

      return bag?.price ?? 0
    },

    whithoutHarvest() {
      return (
        this.semeadura &&
        !this.semeadura.colheita &&
        this.semeadura.ready_to_harvest
      )
    },
  },

  methods: {
    ...mapMutations('execucoes', [
      'SET_SEMEADURA',
      'SET_SEMEADURA_ID',
      'SET_CULTIVAR_ID',
    ]),
    ...mapActions('crops', ['setCurrentCrop']),
    ...mapActions('semeaduras', ['loadSowingsFilter']),
    ...mapActions('execucoes', ['loadPrograma']),
    ...mapActions('program', ['verifyProgramHasWhiteMold']),
    ...mapActions('seasonsCrops', ['fetchDiseases']),

    async loadPlantings() {
      try {
        this.isLoading = true

        await this.loadSowingsFilter()

        const planting = this.semeaduras.find(
          (semeadura) => semeadura.id == this.semeadura.id
        )
        if (!planting) throw new Error('Semeadura atual não encontrada')

        this.SET_SEMEADURA(planting)
        // FIXME: we should garantee that the crop not change in this page
        // it will be important to remove this line, and garantee that the crop is set in the start of the app component
        // we should force only load the sowings of the crop selected before
        this.setCurrentCrop(planting.crop_id)
        this.SET_CULTIVAR_ID(planting.cultivar_id)

        await this.loadPrograma()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },

    refresh() {
      this.isOpenModalHarvest = false
      this.$router.go()
    },

    async colheita() {
      this.semeadura.colheita = null

      await this.loadSowingsFilter()
    },

    openModalHarvest() {
      this.isOpenModalHarvest = true
    },
  },
}
</script>

<template>
  <v-container>
    <permission-failed-message v-if="!hasPermission(this.$p.program.list)" />
    <div v-if="hasPermission(this.$p.program.list)" class="pt-4">
      <loader v-if="isLoading" />
      <div v-else>
        <message-sowing-ready-to-harvest
          class="pr-4 pl-4"
          v-if="whithoutHarvest"
          :sowing="semeadura"
          @open-modal-harvest="openModalHarvest"
        />
        <v-alert
          v-if="semeadura.colheita && !isLoading && enabledFarm"
          text
          dense
          prominent
          type="success"
        >
          <v-row align="center" dense>
            <v-col class="grow">
              <strong>
                {{
                  $t('harvest.sucess_message', [
                    productivity,
                    semeadura.colheita.data_colheita.formatDate(),
                  ])
                }}
              </strong>
            </v-col>
            <v-col class="shrink">
              <v-btn
                outlined
                color="success"
                @click="openModalHarvest(semeadura)"
              >
                <v-icon left> mdi-pencil </v-icon>
                &nbsp;
                {{ $t('editar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <execucao-header
          :show-colheita-btn="isEndOfPrograma && !semeadura.colheita"
          @changed="loadPlantings"
        />
        <div v-if="programa">
          <execucao-programa-header
            :programa="programa"
            :semeadura="semeadura"
            :bag-price="bagPrice"
          />
          <execucao-programa-list class="pr-12 pl-12" :aplicacoes="execucoes" />
        </div>
        <execucao-programa-import v-else />
        <modal-colheita
          v-if="isOpenModalHarvest && semeadura"
          :dialog="isOpenModalHarvest"
          @alterou="colheita"
          @close="refresh"
          :semeadura="semeadura"
        />
      </div>
    </div>
  </v-container>
</template>
