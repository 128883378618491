<template>
  <v-dialog v-model="isOpen" max-width="400" persistent>
    <v-container class="grey lighten-5">
      <v-card-actions class="row-close">
        <v-spacer />
        <v-spacer />
        <v-btn icon color="gray" @click="close" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-row class="pt-5" justify="center" no-gutters>
        <v-col md="auto">
          <v-row class="pa-2">
            <v-img alt="warning" src="icons/ic-warning-green.svg" width="90" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" sm="8" class="offset-md-2 mt-n5">
          <v-list-item>
            <v-list-item-content>
              <div align="center" class="description-enabled">
                {{ $t('register.secundary_title_did_not_fill_all_harvest') }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn
          class="white--text text-none button-register-close"
          @click="close"
        >
          {{ $t('no') }}
          <v-icon class="icon-button" small>mdi-close</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          class="white--text text-none button-register-confirm"
          text
          @click="confirm"
        >
          {{ $t('register.after') }}
          <v-icon class="icon-button" small>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirm',

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.close()
      }
    },
  },

  methods: {
    confirm() {
      this.$emit('confirm')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
::v-deep .v-list-item {
  padding: 0;
}
.row-close {
  margin: -17px -12px;
}
.description-enabled {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.button-register-confirm {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}
.button-register-close {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: rgba(0, 0, 0, 0.4) !important;
}
.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}
</style>
